<template>
  <div
    style="
      position: fixed;
      bottom: 0;
      width: 100%;
      padding: 10px;
      background-color: whitesmoke;
      z-index: 999;
    "
    id="cookieBanner"
    v-if="cookie"
  >
    <div class="flix-container">
      <div
        style="
          position: absolute;
          right: 10px;
          top: 10px;
          width: 50px;
          height: 50px;

        "
      >
        <a href="#" class="flix-btn flix-html-a" style="font-size: 2rem;" @click.prevent="setCookie">X</a>
      </div>
      <div
        class="flix-form-group"
        v-html="$tc('cookie.message', 2).split('\n').join('<br>')"
      ></div>
      <a
        href="#"
        class="flix-btn flix-btn-xs flix-btn-primary"
        @click.prevent="setCookie"
      >
        {{ $tc('cookie.accept', 2) }}
      </a>
      <a
        href="#"
        class="flix-btn flix-btn-xs flix-btn-default"
        @click.prevent="setCookie"
      >
        {{ $tc('cookie.essentials', 2) }}
      </a>
      <p>
        <a
          :href="
            'https://bookingflix.' +
            ($i18n.locale === 'en' ? 'net' : $i18n.locale) +
            '/privacy'
          "
          target="_blank"
          class="flix-html-a flix-underline"
        >
          {{ $tc('cookie.privacy', 2) }}
        </a>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'cookieNotice',
  components: {},
  props: {},
  data() {
    return {
      cookie: true,
      i18n: this.$i18n.locale
    }
  },
  computed: {},
  mounted() {
    this.getData()
  },
  created() {},
  methods: {
    setCookie () {
      this.cookie = false
      localStorage.setItem('cookie_policy', 1)
    },
    async getData() {
      const check = localStorage.getItem('cookie_policy')

      if (check) {
        this.cookie = false
        return false
      }
    }
  }
}
</script>
<style lang="sass" scoped></style>
